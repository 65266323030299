<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Editar dados do paciente</h2>
        <hr />
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="atualizarPaciente">
            <validation-provider
              v-slot="{ errors }"
              name="Nome completo"
              rules="required"
            >
              <v-text-field
                v-model="nomeCompleto"
                label="Nome completo (Obrigatório)"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>

            <v-text-field
              v-model="nomeSocial"
              label="Nome social (Opcional)"
            ></v-text-field>

            <validation-provider name="Nome do pai">
              <v-text-field
                v-model="nomePai"
                label="Nome do pai (Opcional)"
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="Nome da mãe "
              rules="required"
            >
              <v-text-field
                v-model="nomeMae"
                label="Nome da mãe (Obrigatório)"
                :error-messages="errors"
                required
              ></v-text-field>
            </validation-provider>

            <div class="row">
              <div class="col-md-3">
                <validation-provider name="UBS">
                  <v-text-field
                    v-model="ubs"
                    label="UBS (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Cartão SUS">
                  <v-text-field
                    v-model="sus"
                    label="Cartão SUS (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="RG">
                  <v-text-field
                    v-model="rg"
                    label="RG (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="CPF"
                  rules="required"
                >
                  <v-text-field
                    v-model="cpf"
                    label="CPF (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">

                <div class="col-md-3 mt-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Sexo "
                  rules="required"
                >
                  <b-form-select
                    v-model="sexo"
                    :error-messages="errors"
                    :options="options2"
                    required
                  ></b-form-select>
                </validation-provider>
              </div>

              <div class="col-md-3 mt-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Estado civil"
                  rules="required"
                >
                  <b-form-select
                    style="border-color: black"
                    v-model="estadoCivil"
                    :error-messages="errors"
                    :options="options"
                    required
                  ></b-form-select>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Telefone"
                  rules="required"
                >
                  <v-text-field
                    v-model="telefone"
                    label="Telefone (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Data de nascimento"
                  rules="required"
                >
                  <v-text-field
                    type="date"
                    v-model="dataNascimento"
                    label="Data de nascimento (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
           
            </div>

            <div class="row">
                 <div class="col-md-3">
                <validation-provider name="Município">
                  <v-text-field
                    v-model="municipio"
                    label="Município (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Rua">
                  <v-text-field
                    v-model="rua"
                    label="Rua (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Número">
                  <v-text-field
                    v-model="numero"
                    label="numero (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-3">
                <validation-provider name="Bairro">
                  <v-text-field
                    v-model="bairro"
                    label="bairro (Opcional)"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              @click="atualizarPaciente"
              :disabled="invalid"
              class="mr-4 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Atualizar</span>
            </v-btn>

            <router-link to="/PainelPacientes" v-slot="{ href, navigate }">
              <a :href="href" class="menu-link" @click="navigate">
                <v-btn class="textStyle" tile color="#1dd1a1">
                  <span style="color: white">Voltar</span>
                </v-btn>
              </a>
            </router-link>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido"
});

export default {
  name: "AtualizarPaciente",
  components: {
    ValidationProvider,
    ValidationObserver
  },

  async created() {
    try {
      const { data } = await ambulatorio.indexPaciente(this.$route.params.id);
      console.log({nome: "editar infor", dados: data})
      this.sexo = data.pessoa.sexo_id;
      this.nomeCompleto = data.pessoa.nome;
      this.nomeSocial = data.nome_social;
      this.nomeMae = data.nome_mae;
      this.nomePai = data.nome_pai;
      this.telefone = data.telefone;
      this.ubs = data.ubs;
      this.sus = data.sus;
      this.prontuario = data.prontuario;
      this.prioridade = data.prioridade;
      this.estadoCivil = data.estado_civil_id;
      this.rua = data.endereco.rua;
      this.numero = data.endereco.numero;
      this.bairro = data.endereco.bairro;
      this.municipio = data.endereco.municipio;
      this.cpf = data.pessoa.cpf;
      this.rg = data.pessoa.rg;
      this.dataNascimento = data.pessoa.data_nascimento;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
        options2: [
        { value: null, text: "Selecione um gênero (Obrigatório)" },
        { value: 1, text: "Masculino" },
        { value: 2, text: "Feminino" },
        { value: 3, text: "Não informar" },
      ],
      nomeSocial: "",
      nomeMae: "",
      nomePai: "",
      telefone: "",
      ubs: "",
      sus: "",
      prontuario: "",
      prioridade: "false",
      estadoCivil: null,
      rua: "",
      numero: "",
      bairro: "",
      municipio: "",
      nomeCompleto: "",
      dataNascimento: "",
      rg: "",
      cpf: "",
      sexo: null,
      sexoId: "1",
       options: [
        { value: null, text: "Selecione um status social (Obrigatório)" },
        { value: 5, text: "Solteiro(a)" },
        { value: 4, text: "Casado(a)" },
        { value: 1, text: "Divorciado(a)" },
        { value: 2, text: "Viúvo(a)" },
        { value: 3, text: "União Estável" },
        { value: 6, text: "Outro" },
      ]
    };
  },

  methods: {
    atualizarPaciente: async function() {
      var data = {
        paciente: {
          nome_social: this.nomeSocial,
          nome_mae: this.nomeMae,
          nome_pai: this.nomePai,
          telefone: this.telefone,
          ubs: this.ubs,
          sus: this.sus,
          prontuario: this.prontuario,
          prioridade: this.prioridade,
          estado_civil_id: this.estadoCivil,
          endereco_attributes: {
            rua: this.rua,
            numero: this.numero,
            bairro: this.bairro,
            municipio: this.municipio
          },
          pessoa_attributes: {
            nome: this.nomeCompleto,
            data_nascimento: this.dataNascimento,
            rg: this.rg,
            cpf: this.cpf,
            sexo_id: this.sexo
          }
        }
      };

      try {
        await ambulatorio.atualizarPaciente(data, this.$route.params.id);

        Swal.fire({
          title: "",
          text: "Paciente atualizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });

        setTimeout(() => {
          this.$router.push({ name: "painelPacientes" });
        }, 1000);
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Atualizar dados do paciente" }
    ]);
  }
};
</script>

<style>
.theme--light.v-messages {
  color: red !important ;
}
</style>
